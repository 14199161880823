import React from "react";
import { graphql, Link } from "gatsby";
import { PrismicRichText } from "@prismicio/react";

// Layout
import { Page } from "../components/containers/page";

// SEO
import { PageSeo } from "../components/seo/page-seo";

// Previews
import { withPrismicPreview } from "gatsby-plugin-prismic-previews";

// Utils
import { ConditionalWrapper } from "../components/utils/conditional-wrapper";

const Exhibitions = ({ data }) => {
  const content = data.allPrismicExhibition.nodes.map((exhibition, index) => {
    return (
      <li
        key={`single_exhibition_${index}_${exhibition.id}`}
        className="single-exhibition overview-grid list-item"
        id={exhibition.id}
      >
        <div className="column left">
          <p>{exhibition.data.date_year}</p>
        </div>

        <div className="column right">
          <ConditionalWrapper
            condition={exhibition.data.is_exhibition_forthcoming !== true}
            wrapper={(children) => (
              <Link to={exhibition.url} state={{ section: exhibition.id }}>
                {children}
              </Link>
            )}
          >
            <p>{exhibition.data.forthcoming}</p>

            <PrismicRichText
              field={exhibition.data.title_of_show.richText}
              components={{
                heading1: ({ children }) => (
                  <h1 className="italic">{children}</h1>
                ),
              }}
            />

            <p className="small-caps">
              {exhibition.data.solo_or_group_show_text}
            </p>
            <p>{exhibition.data.date_full}</p>
            <p>{exhibition.data.location}</p>
          </ConditionalWrapper>
        </div>
      </li>
    );
  });

  return (
    <>
      <PageSeo seoTitle={`Exhibitions`} seoImage={null} seoText={null} />
      <Page>
        <div className="page-grid">
          <div className="spacer" />

          <div className="content-container">
            <ol>{content}</ol>
          </div>

          <div className="spacer" />
        </div>

        <div className="page-bottom-border">
          <div className="border" />
        </div>
      </Page>
    </>
  );
};

export default withPrismicPreview(Exhibitions);

export const query = graphql`
  query Exhibitions {
    allPrismicExhibition(sort: { fields: data___sort_date, order: DESC }) {
      nodes {
        id
        url
        data {
          forthcoming
          is_exhibition_forthcoming
          title_of_show {
            richText
          }
          solo_or_group_show_text
          date_full
          location
          date_year
          body {
            ... on PrismicExhibitionDataBodyGallery {
              id
              slice_type
              items {
                image {
                  gatsbyImageData(breakpoints: [1000, 1500, 2000, 3000, 4000])
                }
              }
            }
            ... on PrismicExhibitionDataBodyWorksImages {
              id
              slice_type
              items {
                image {
                  gatsbyImageData(breakpoints: [1000, 1500, 2000, 3000, 4000])
                }
              }
            }
            ... on PrismicExhibitionDataBodyText {
              id
              slice_type
            }
          }
        }
      }
    }
  }
`;
